import { component, html } from './deps';
import post from './post';

export default class Sponsor extends component {
    static tag = 'x-sponsor';

    global = {
        year: NaN
    }

    sponsors = {
        'florence nightingale': [],
        'platinum': [],
        'diamond': [],
        'keynote speaker sponsor': [],
        'photo sponsor': [],
        'emerald': [],
        'ruby': [],
        'turquoise': [],
        'friend of nursing': [],
    };

    async connected() {
        this.global = await post('/global');
        
        // TODO: this should not be -1
        this.global.year--;

        const result: { type: string }[] = await post('/sponsors', { year: this.global.year });
        result.forEach(sponsor => this.sponsors[ sponsor.type ].push(sponsor));
        console.log(this.sponsors);
    }

    render = () => html`
     <style>
        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            background: lightgrey;
        }
    </style>

    <div class="container padding-m background-primary">
        <div class="margin-vertical-l">
            <h1 class="display-title color-white">Sponsor</h1>
            <hr class="divider background-white">
            <p class="lead lead-s margin-bottom-m color-white">Consider becoming a sponsor of Tucson Nurses Week Foundation.</p>
        </div>
    </div>
    <div class="container padding-m">
        <h2>${this.global.year} Sponsors</h2>
        <div class="flex flex-row margin-vertical-m">
            <div class="flex-column">
                <hr>
            </div>
        </div>
        <div class="flex flex-row margin-vertical-m">
            <div class="flex-column">
                <h3>Florence Nightingale</h3>
                <hr>
            </div>
        </div>
        <div class="flex flex-row justify-content-space-between flex-wrap">
            ${this.sponsors['florence nightingale'].map(sponsor => html`
                <div class="flex flex-column justify-content-center max-width-xs" style="margin-bottom: 2rem">
                    <h4>${sponsor.name}</h4>
                    <img class="sponsor-img" src=${'/bucket/sponsor/' + sponsor.id} alt="sponsor" loading="lazy"/>
                    <p>${sponsor.facility}</p>
                </div>
            `)}
            ${!this.sponsors['florence nightingale'].length ? html`
                <div class="flex flex-column justify-content-center align-items-center">
                    <span>No sponsors at this level</span>
                <div>
            ` : ''}
        </div>

        <div class="flex flex-row margin-vertical-m">
            <div class="flex-column">
                <h3>Turquoise</h3>
                <hr>
            </div>
        </div>
        <div class="flex flex-row justify-content-space-between flex-wrap">
            ${this.sponsors['turquoise'].map(sponsor => html`
                <div class="flex flex-column justify-content-center" style="margin-bottom: 2rem">
                    <h4>${sponsor.name}</h4>
                    <img class="sponsor-img" style="max-height: 100px" src=${'/bucket/sponsor/' + sponsor.id} alt="sponsor" loading="lazy"/>
                    <p>${sponsor.facility}</p>
                </div>
            `)}
            ${!this.sponsors['turquoise'].length ? html`
                <div class="flex flex-column justify-content-center align-items-center">
                    <span>No sponsors at this level</span>
                <div>
            ` : ''}
        </div>

    
        <h1>Tucson Nurses Week Foundation Sponsorships 2024</h1>

        <p>Tucson Nurses' Week Foundation is a charitable organization that promotes and recognizes outstanding Nursing practices in the greater Tucson area. Everything we do is made possible by your generosity. We host, annually, an educational Conference and Healthy Fair as well as a Gala to celebrate our Tucson Nurses.</p>
        <p>Please consider becoming a sponsor of Tucson Nurses' Week! In addition to any donation of your preference, we have many tiers of sponsorship that we encourage you or your organization to consider. All sponsors are recognized on our site, on our social media channels, and at the yearly festivities to show our appreciation of their support.</p>
        <hr>

        <h3>Florence Nightingale - &#36;25,000</h3>
        <ul>
            <li>Recognition at the Gala (two minutes to address the audience)</li>
            <li>Two tables at the Gala</li>
            <li>Sponsor BOGO at the Gala</li>
            <li>Two page full color ad in the program booklet </li>
            <li>Exhibit at the Healthy Fair (premium position)</li>
            <li>Logo/mentions in  promotional advertising in print and online</li>
            <li>Sponsorship ad  on the TNWF website </li>
            <li>Display of the TNWF “Proud Supporter” logo on your website </li>
        </ul>

        <h3>Platinum - &#36;15,000</h3>
        <ul>
            <li>Recognition at the Gala</li>
            <li>Two tables at the Gala</li>
            <li>Sponsor BOGO at the Gala</li>
            <li>Full page full color ad in the program booklet </li>
            <li>Exhibit at the Healthy Fair (premium position)</li>
            <li>Logo/mentions in  promotional advertising in print and online</li>
            <li>Sponsorship ad on the TNWF website </li>
            <li>Display of the TNWF “Proud Supporter” logo on your website </li>
        </ul>

        <h3>Diamond - &#36;10,000</h3>
        <ul>
            <li>Recognition at the Gala</li>
            <li>One table at the Gala</li>
            <li>Sponsor BOGO at the Gala</li>
            <li>Full page full color ad in the program booklet </li>
            <li>Exhibit at the Healthy Fair (premium position)</li>
            <li>Logo/mentions in  promotional advertising in print and online</li>
            <li>Sponsorship ad  on the TNWF website </li>
            <li>Display of the TNWF “Proud Supporter” logo on your website </li>
        </ul>

        <h3>Keynote Speaker Sponsor $10,000:</h3> 
        <p>(Directly contributes to recruiting prominent keynote speakers for the TNWF conference.)</p>
        <ul>
            <li>Recognition at the Conference (two minutes to address the audience and introduce the keynotes)</li>
            <li>Full page full color ad in the program booklet </li>
            <li>Logo/mentions in  promotional advertising in print and online</li>
            <li>Sponsorship ad  on the TNWF website </li>
            <li>Display of the TNWF “Proud Supporter” logo on your website </li>
        </ul>

        <h3>Photo Sponsor $7,500:</h3>
        <p>(Funds professional photography for TNWF winner headshots and event photography.)</p>
        <ul>
            <li>Recognition at the Gala (poster at headshot display)</li>
            <li>Half page full color ad in the program booklet </li>
            <li>Exhibit at Healthy Fair</li>
            <li>Logo/mentions in  promotional advertising in print and online</li>
            <li>Sponsorship ad  on the TNWF website </li>
            <li>Display of the TNWF “Proud Supporter” logo on your website </li>
        </ul>

        <h3>Emerald - &#36;7,500</h3>
        <ul>
            <li>Recognition at the Gala</li>
            <li>One table at the Gala</li>
            <li>Sponsor BOGO at the Gala</li>
            <li>Half page full color ad in the program booklet </li>
            <li>Exhibit at the Healthy Fair</li>
            <li>Logo/mentions in  promotional advertising in print </li>
            <li>Inclusion in sponsor list  on the TNWF website</li>
            <li>Display of the TNWF “Proud Supporter” logo on your website</li>
        </ul>

        <h3>Ruby - &#36;5,000</h3>
        <ul>
            <li>Recognition at the Gala</li>
            <li>Four tickets to the Gala</li>
            <li>Quarter page full color ad in the program booklet </li>
            <li>Exhibit at the Healthy Fair</li>
            <li>Logo/mentions in  promotional advertising in print</li>
            <li>Inclusion in sponsor list  on the TNWF website</li>
            <li>Display of the TNWF “Proud Supporter” logo on your website </li>
        </ul>

        <h3>Turquoise - &#36;1,000</h3>
        <ul>
            <li>Eighth page full color ad in the program booklet </li>
            <li>Exhibit at the Healthy Fair</li>
            <li>Mentions in  promotional advertising in print and online</li>
            <li>Inclusion in sponsor list  on the TNWF website</li>
            <li>Display of the TNWF “Proud Supporter” logo on your website</li>
        </ul>

        <h3>Friend of Nursing - &#36;500</h3>
        <ul>
            <li>Inclusion in sponsor list in the program booklet and TNWF website</li>
            <li>Exhibit at the Healthy Fair</li>
            <li>Display of the TNWF “Proud Supporter” logo on your website </li>
        </ul>
        <p><strong>Mailing address:</strong> P.O. Box 65085, Tucson, Arizona 85728-5085</p>

        <p>**All artwork must be in .PSD or .EPS/.AI format. Please see the zone examples link on the TNWF website for placement.</p>

        <p>In addition to these, we gladly accept donations of any amount. All monies go directly into paying for the events and supporting Tucson Nurses' Week Foundation. Fabulous 50 Alumni donations go directly to paying for student lunches at the Conference and Healthy Fair as well as the Clinical Nursing Research Grant.</p>
        <a class="button button-primary" href="/donate">Consider a Donation - Donate Here</a>

    </div>
    `

}