import { component, html } from './deps';
import { Form } from '../database/form';
import result from '../modules/results';
import post from './post';
import get from './get';

const preview = '/admin/preview';

// const elements = stripe.elements({
//     mode: 'payment',
//     currency: 'usd',
//     amount: 1000,
// });
// const cardElement = elements.create('card');

export default class XForm extends component {
    static tag = 'x-form';

    form: Form | any = {};
    source: Form | any = {};

    total = 0;

    name = '';
    phone = '';
    email = '';

    cardName = '';
    cardNumber = '';
    cardCode = '';
    cardMonth = '';
    cardYear = '';

    disabled = false;
    submitted = false;

    async submit(e) {
        e.preventDefault();
        this.disabled = true;

        const url = new URL(location.href);
        const path = url.pathname;
        const { form, name, phone, email, cardName, cardNumber, cardCode, cardMonth, cardYear } = this;

        // const stripeSubmit = await elements.submit();
        // const stripeToken = await stripe.createToken(cardElement);

        if (path !== preview) {
            let token;
            if (form.payment) {
                const { stripePublicKey } = await get('/variables');
                const result = await fetch('https://api.stripe.com/v1/tokens', {
                    method: 'POST',
                    headers: {
                        'Stripe-Version': '2023-08-16',
                        'Authorization': `Bearer ${stripePublicKey}`,
                    },
                    body: new URLSearchParams({
                        'card[cvc]': cardCode,
                        'card[name]': cardName,
                        'card[number]': cardNumber,
                        'card[exp_year]': cardYear,
                        'card[exp_month]': cardMonth,
                    })
                });

                const data = await result.json();

                if (data.error) {
                    const message = `Card Error\n${data.error.message}`;
                    this.disabled = false;
                    alert(message);
                    throw new Error(message);
                }

                token = data.id;
            }

            await post('/submit', { form, name, phone, email, token });
        }

        this.total = 0;

        this.email = '';
        this.name = '';
        this.phone = '';

        this.cardName = '';
        this.cardNumber = '';
        this.cardCode = '';
        this.cardYear = '';
        this.cardMonth = '';

        this.submitted = true;
        this.disabled = false;
        this.form = JSON.parse(JSON.stringify(this.source));

        setTimeout(() => this.submitted = false, 30_000);

        e.target.reset();
    }

    async input () {
        if (!this.form?.payment) return;
        setTimeout(() => {
            const { items, amount } = result(this.source, this.form);
            this.total = amount;
        });
    }

    async connected() {
        const url = new URL(location.href);
        const path = url.pathname;

        if (path === preview) {
            const id = url.searchParams.get('id');
            this.source = await post('/form', { id });
        } else {
            this.source = await post('/form', { path });
        }

        this.form = JSON.parse(JSON.stringify(this.source));
    }

    // async rendered() {
    //     cardElement.mount('#card');
    // }

    render = () => html`

        ${this.form.status === false ? html`
            <div class="flex flex-row justify-content-center align-items-center">
                <h2>We are no longer accepting entries.</h2>
            </div>
        ` : ''}

        ${this.submitted ? html`
            <div class="flex flex-row justify-content-center align-items-center">
                <h2>Thank You For Registering</h2>
            </div>
        ` : ''}

        ${ this.form.status === true && !this.submitted ? html`
            <form onsubmit=${this.submit} oninput=${this.input}>

                ${this.form?.sections?.map(section => html`
                <fieldset ${this.disabled ? 'disabled' : ''}>
                    <legend>${section.name}</legend>
                    ${section.fields.map(field => html`
                        ${field.type === 'text' ? html`
                        <label class="field">
                            <div>${field.name}</div>
                            <input type="text" name=${field.name} class="" ${field.required ? 'required' : ''} oninput=${e => field.value = e.target.value}/>
                        </label>
                        `: ''}
                        ${field.type === 'checkbox' ? html`
                        <label class="field">
                            <div>${field.name}</div>
                            <input type="checkbox" name=${field.name} class="" ${field.required ? 'required' : ''} oninput=${e => field.value = e.target.checked}/>
                        </label>
                        `: ''}
                        ${field.type === 'number' ? html`
                        <label class="field">
                            <div>${field.name}</div>
                            <input type="number" name=${field.name} class="" ${field.required ? 'required' : ''} oninput=${e => field.value = e.target.valueAsNumber}/>
                        </label>
                        `: ''}
                        ${field.type === 'select' ? html`
                        <label class="field">
                            <div>${field.name}</div>
                            <select name=${field.name} ${field.multiple ? 'multiple' : ''} oninput=${e => field.value = [...e.target.selectedOptions].map(o => o.value).join(', ')}>
                                ${field.options?.map(option => html`
                                    <option value=${option.value}>${option.name}</option>
                                `)}
                            </select>
                        </label>
                        `: ''}
                        ${field.type === 'textarea' ? html`
                        <label class="field">
                            <div>${field.name}</div>
                            <textarea name=${field.name} ${field.required ? 'required' : ''} oninput=${e => field.value = e.target.value} maxlength="1500"></textarea>
                        </label>
                        `: ''}
                    `)}
                </fieldset>
                `)}

                <fieldset ${this.disabled ? 'disabled' : ''}>
                    <legend>Submitter Details</legend>
                    <label class="field">
                        <div>Submitter Name</div>
                        <input type="text" autocomplete="name" name="Name" value=${this.name} oninput=${e => this.name = e.target.value} />
                    </label>
                    <label class="field">
                        <div>Submitter Phone</div>
                        <input type="phone" autocomplete="phone" name="phone" placeholder="Phone" value=${this.phone} oninput=${e => this.phone = e.target.value} />
                    </label>
                    <label class="field">
                        <div>Submitter Email</div>
                        <input type="email" autocomplete="email" name="email" placeholder="Email" value=${this.email} oninput=${e => this.email = e.target.value} />
                    </label>
                </fieldset>

                ${this.form?.payment ? html`
                <fieldset ${this.disabled ? 'disabled' : ''}>
                    <legend>Payment Details</legend>
                    <div class="card">
                        <label class="field">
                            <div>Card Name</div>
                            <input type="text" autocomplete="cc-name" name="cc-name" placeholder="Card Name" value=${this.cardName} oninput=${e => this.cardName = e.target.value} required />
                        </label>
                        <label class="field">
                            <div>Card Number</div>
                            <input type="text" autocomplete="cc-number" name="cc-number" placeholder="Card Number" pattern="[0-9]{16}" value=${this.cardNumber} oninput=${e => this.cardNumber = e.target.value} required />
                        </label>
                        <label class="field">
                            <div>Code</div>
                            <input type="text" autocomplete="cc-csc" name="cc-csc" placeholder="Code" pattern="[0-9]{3,4}" value=${this.cardCode} oninput=${e => this.cardCode = e.target.value} required />
                        </label>
                        <label class="field">
                            <div>Month</div>
                            <input type="text" autocomplete="cc-exp-month" name="cc-month" placeholder="Month" pattern="0[1-9]|10|11|12" value=${this.cardMonth} oninput=${e => this.cardMonth = e.target.value} required />
                        </label>
                        <label class="field">
                            <div>Year</div>
                            <input type="text" autocomplete="cc-exp-year" name="cc-year" placeholder="Year" pattern="[0-9]{2}" value=${this.cardYear} oninput=${e => this.cardYear = e.target.value} required />
                        </label>
                        <div class="card-total">$${this.total}</div>
                    </div>
                </fieldset>
                ` : ''}

                <div class="flex flex-row align-items-center padding-s">
                    <button class="button button-primary button-border" type="submit" ${this.disabled ? 'disabled' : ''}>Submit</button>
                    ${this.disabled ? html`<span class="spinner flex-1-1-auto"></span>` : ''}
                </div>

            </form>
        ` : '' }

    `

}
