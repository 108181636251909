import { component, html } from './deps';

export default class Registration extends component {
    static tag = 'x-registration';

    registrationType = '';
    id: string;

    async connected() {
        // const urlParams = new URLSearchParams(window.location.search);
        // this.registrationType = urlParams.get('type') || '';

        const urlParams = new URLSearchParams(window.location.search);
        this.registrationType = location.pathname.split('/').pop() || '';

        const id = new URL(location.href).searchParams.get('id');
        this.id = id ?? '';
    }

    render = () => html`

    <div class="container padding-m background-primary">
        <div class="margin-vertical-l">
            <h1 class="display-title color-white">${this.registrationType} Registration</h1>
            <hr class="divider background-white">
            <p class="lead lead-s margin-bottom-m color-white">Fill out form below to register for event.</p>
        </div>
    </div>

    <div class="container padding-m">
        <x-form></x-form>
    </div>

    `

}