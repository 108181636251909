import { component, html } from './deps';

export default class Nominate extends component {
    static tag = 'x-nominate';

    nominationType = '';
    id: string;

    nominationTypes = {
        'top-5': 'Top 5 LPN',
        'fabulous-50': 'Fab 50 RN',
        'alex-stuetze': 'Alex Stuetze Award - Pediatric Award',
        'inspirational-mentor': 'Most Inspirational Mentor - Must be a previous Fab 50 winner'
    };

    async connected() {
        const urlParams = new URLSearchParams(window.location.search);
        this.nominationType = location.pathname.split('/').pop() || '';
        this.id = urlParams.get('id') || '';
    }

    render = () => html`

    <div class="container padding-m background-primary">
        <div class="margin-vertical-l">
            <h1 class="display-title color-white">${this.nominationTypes[this.nominationType]} Nomination | Deadline Jan 26, 2025</h1>
            <hr class="divider background-white">
            <p class="lead lead-s margin-bottom-m color-white">Fill out form below to nominate a nurse for the ${this.nominationTypes[this.nominationType]} award.</p>
        </div>
    </div>

    <div class="container padding-m">
        <x-form></x-form>
    </div>

    `

}